import React, {useEffect, useState} from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import { Purchase } from "../utils/wallet";
import { ALL_LISTINGS } from "utils/GetListings";
import config from "../config";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function GetFeatured() {

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


let searchRecords = [];
const [search, setSearch] = useState('');
const {data} = useQuery(ALL_LISTINGS);
const [tokenName, settokenName] = useState([]);
const [loading, setLoading] = useState(true);
const currentPage = 1;
const [recordsPerPage] = useState(20);
const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {
      if (data) {
          settokenName(data.listing);
          setLoading(false);
      }
  }, [data]);


tokenName.filter((item) => {
                let setroyalties = [];
                let setroyaltiesOld = [];
                let setroyaltiesBurn = [];

                  {item.token.royalties.map((shares) => {
                            
                            let share = shares.amount / Math.pow(10, shares.decimals);
                            
                            share = share * Math.pow(10, 4);
                            
                            share = Math.round(share);
                            let set = { amount : share.toString(), recipient: shares.receiver_address };
                            setroyalties.push(set);
                            setroyaltiesOld.push(set);
                            setroyaltiesBurn.push(set);

                        })}
                
                let dao = { amount : '100', recipient: "KT1LyPqdRVBFdQvhjyybG5osRCXnGSrk15M5" };
                let dao2 = { amount : '100', recipient: "KT1CZMurPAjSfZqcn6LBUNUhG4byE6AJgDT6" };
                let platform = { amount : '69', recipient: "tz1QiuWYuHQSXrwP3LceitzGW137jUaRescK" };
                let platform2 = { amount : '70', recipient: "tz1QiuWYuHQSXrwP3LceitzGW137jUaRescK" };
                setroyalties.push(dao);
                setroyalties.push(platform);
                setroyaltiesOld.push(dao);
                setroyaltiesOld.push(platform2);
                setroyaltiesBurn.push(dao2);
                setroyaltiesBurn.push(platform);

                return item
                  ? config.featuredCurrency.includes(item.currency.fa_contract) || config.featuredListings.includes(item.token.fa_contract)
                    ? JSON.stringify(item.shares) === JSON.stringify(setroyalties) || JSON.stringify(item.shares) === JSON.stringify(setroyaltiesOld) || JSON.stringify(item.shares) === JSON.stringify(setroyaltiesBurn)
                    : ""
                  : ""}
                )
                .map((item) => (
                  
                 searchRecords.push(item)
                ))

  const currentRecords = searchRecords.slice(indexOfFirstRecord, 
                                    indexOfLastRecord);

  return(
    <>
    
        <div id="newlistings"></div>
        <section >
          <Container className="mt-2 pb-4" >
            <Row>
              <Col md="6">
                
                <h1>
                  Featured{" "}
                  <span className="text-info">Listings</span>
                  <hr className="line-info" />
                </h1>

              </Col>
               
              <Col>
              <p align="right"><a href="/listings"><font color="white">Explore All ➜</font></a></p></Col>
            </Row>


            <center>
            { tokenName.length 
              ? "" 
              : loading 
                ? "Loading..."
                : "🐸 No Listings Found! 🐸"
              }
            
            </center>
             
            
             <Carousel 
              swipeable={true}
              //centerMode={true}
              renderDotsOutside={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={7000}
              keyBoardControl={true}
              customTransition="transform 300ms ease-in-out"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={false}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
  >

              {currentRecords.map((val) => {
                
                 return (
                
                <div>
                  
                   <Col xs="12" sm="12" md="12" className="mt-5">
                <Card className="card-coin bg-plain mb-0">
                  <CardHeader className="text-uppercase ">
                    <Card className="card-coin bg-plain mt-5 mb-0 p-1">
                    <a href={`/token/${val.token.fa_contract}/${val.token.token_id}`} >
                    <img
                      alt="..."
                      style={{ width: "200px" }}
                      className="img-center img-fluid"
                      src={ val.token.fa_contract === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw" ? require("assets/img/plenty.jpg")?.default : val.token.thumbnail_uri ? val.token.thumbnail_uri.slice(0,4) === "http" ? val.token.thumbnail_uri : val.token.thumbnail_uri != "ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc" ? (`https://cf-ipfs.com/ipfs/${val.token.thumbnail_uri.substring(7)}`) : val.token.display_uri ? val.token.display_uri.slice(0,4) === "http" ? val.token.display_uri : (`https://cf-ipfs.com/ipfs/${val.token.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg") : val.token.display_uri ? val.token.display_uri.slice(0,4) === "http" ? val.token.display_uri : (`https://cf-ipfs.com/ipfs/${val.token.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                    /></a>
                    </Card>
                 
                    
                    <p align="right"><font color="white"><sub>{val.amount_left} / {val.token.supply}</sub></font></p>
                 
                  </CardHeader>
                  <center><a href={`/token/${val.token.fa_contract}/${val.token.token_id}`} ><big><font color="white" className="font-uppercase">{val.token.name ? val.token.name : "???"}</font></big></a>
                  <hr className="line-default" />
                   <font color="white" className="font-uppercase"><sup>{val.token.fa.name ? val.token.fa.name : ""}</sup>
                  <br /> <sup>{val.token.fa.creator ?  "🎨" : ""}{val.token.fa.creator ? val.token.fa.creator.alias ? val.token.fa.creator.alias : val.token.fa.creator.tzdomain ? val.token.fa.creator.tzdomain : val.token.fa.creator.address ? val.token.fa.creator.address.slice(0, 4) +
                    "..." +
                    val.token.fa.creator.address.slice(val.token.fa.creator.address.length - 4, val.token.fa.creator.address.length) : "" : ""}</sup></font>
                  </center>
                  
                  
                  <CardBody className="text-center">
                    
                    
                  
                      <Button className="btn-simple" color={
                        val.currency.fa_contract === config.poleContract ? "neutral" :
                        val.currency.fa_contract === config.mekContract ? "danger" : 
                        val.currency.fa_contract === config.uczContract ? "primary" : 
                        val.currency.fa_contract === config.ottezContract ? "secondary" :
                        val.currency.fa_contract === config.skullContract ? "default" : 
                        val.currency.fa_contract === config.trashContract ? "info" : 
                        val.currency.fa_contract === config.dkrbtContract ? "secondary" :
                        "success" } 
                        onClick={() => Purchase(1, val.bigmap_key, val.currency.fa_contract, val.price)}
                        >
                        {
                        val.currency.fa_contract === config.poleContract ? (val.price / 100000000) + " 💈 $POLE" :
                        val.currency.fa_contract === config.mekContract ? (val.price / 100000) + " 👾 $MEK" :
                        val.currency.fa_contract === config.uczContract ? (val.price / 100000000) + " 🖳 $UCZ" :
                        val.currency.fa_contract === config.ottezContract ? (val.price / 100000000) + " 🦦 $OTTEZ" :
                        val.currency.fa_contract === config.skullContract ? (val.price / 1000) + " ☠️ $SKULL" :
                        val.currency.fa_contract === config.trashContract ? (val.price / 100000000) + " 🗑️ $TRASH" :
                        val.currency.fa_contract === config.dkrbtContract ? (val.price / 100) + " 🍑 $DKRBT" : 
                        (val.price / 100) + " 🐸 $PEPE"}
                      </Button>
                    
                    
                  </CardBody>
                </Card>
              </Col>


                </div>
                        );})}

            </Carousel>
          </Container>
          
        </section>
        
        </>
        )

}
