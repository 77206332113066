import React, {useEffect, useState} from "react";
import { useQuery, InMemoryCache, gql } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import {
  Button,
  Card,
  CardHeader,
  CardBody, 
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal
} from "reactstrap";
import { CreateListing, getActiveAccount } from "../utils/wallet";
import config from '../config'


export default function Create() {
const [search, setSearch] = useState('');
const [focus, setFocus] = useState( {
      focused: ""
    });
const [currency, setCurrency] = useState("🐸 $PEPE");
const [wallet, setWallet] = useState(null);
const [price, setPrice] = useState("0");
const [listQuantity, setListQuantity] = useState("1");
const [feesModal, setFeesModal] = React.useState(false);



useEffect(() => {
    const func = async () => {
      const account = await getActiveAccount();
      if (account) {
        setWallet(account.address);
      }
    };
    func();
      }, []);

const LOAD_LISTINGS = gql `
query MyQuery($offset: Int) {
  token(
    where: {holders: {holder_address: {_eq: "${wallet}"}, quantity: {_gt: "0"}}}
    order_by: {timestamp: desc}
    offset: $offset
  ) {
    token_id
    fa_contract
    display_uri
    name
    supply
    thumbnail_uri
    fa {
      name
      creator {
        address
        alias
        tzdomain
      }
    }
    creators {
      creator_address
    }
    holders(where: {holder_address: {_eq: "${wallet}"}}) {
      quantity
    }
    royalties {
      receiver_address
      amount
      decimals
    }
  }
}
`
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token: offsetLimitPagination()
      },
    },
  },
});


const {data, fetchMore} = useQuery(LOAD_LISTINGS, {
  variables: {
    offset: 0,
  },
});

const [tokenName, settokenName] = useState([]);
let searchRecords = [];
const [loading, setLoading] = useState(true);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(24);
const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
if (data) {      
  if (data.token.length === 500) {
    fetchMore({
      variables: {
        offset: 500,
      },
      updateQuery: (previousResult, { fetchMoreResult, variables }) => {
        return {
          ...previousResult,
          token: [...previousResult.token, ...fetchMoreResult.token],
        };
      },
    })
  }
}

  useEffect(() => {
      if (data) {
          if (data.token.length % 500 === 0){
            
            fetchMore({
              variables: {
              offset: data.token.length,
              },
              updateQuery: (previousResult, { fetchMoreResult, variables }) => {
                return {
                  ...previousResult,
                  token: [...previousResult.token, ...fetchMoreResult.token],
                };
              },
            })
          }
          settokenName(data.token);
          setLoading(false);
      } 
  }, [data]);


 tokenName.filter((item) => {
                return item
                        ? search.toLowerCase() === '' 
                          ? item
                          : JSON.stringify(item).toLowerCase().includes(search) 
                        : ""}
                )
                .map((item) => (
                 searchRecords.push(item)
                ))
          

const currentRecords = searchRecords.slice(indexOfFirstRecord, 
                                    indexOfLastRecord);
const nPages = Math.ceil(searchRecords.length / recordsPerPage)
const pageNumbers = [...Array(nPages + 1).keys()].slice(1)                
              
              



    

    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }



  return(
    <>

        
        <div id="createlisting"></div>
        <section >
          <Container className="mt-5">
            <Row>
              <Col md="8">
                <hr className="line-info" />
                <h1>
                  Create a{" "}
                  <span className="text-info">Listing</span><br />
                  
                  <Button className="btn-simple border-0 p-0" size="sm" color="default" onClick={() => setFeesModal(true)}>
                      Listings are in $PEPE, $POLE, $MEK, $UCZ, $OTTEZ, $SKULL, $TRASH or $DKRBT - <b>not</b> $XTZ<br/>
                      <sup>Final sales are subject to ~4.2% fee. Click here to learn more.</sup>
                    </Button>
                                  <Modal modalClassName="modal-black" isOpen={feesModal} toggle={() => setFeesModal(false)}>
                          <div className="modal-header justify-content-center">
                            <button className="close" onClick={() => setFeesModal(false)}>
                              <i className="tim-icons icon-simple-remove" />
                            </button>
                            <h4>&nbsp;</h4>
                          </div>
                          <div className="modal-body">
                            <Col sm="12" md="12" className="mt-0">
                            <Card className="p-5">
                              <center>
                                
                                <b>All completed sales will include:</b><br /><br />
                              
                                2.5% objkt.com fee<br />
                                0.69% platform fee<br />
                                1% tezosPepeDAO royalty / burn<br />
                                <sup>fees are in addition to any artist royalty</sup><br /><br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="success" href="https://pepeswap.tez.page/" target="_blank">$PEPE Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="neutral" href="https://3route.io/swap/xtz/pole" target="_blank">$POLE Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="danger" href="https://3route.io/swap/xtz/mek" target="_blank">$MEK Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="primary" href="https://3route.io/swap/xtz/ucz" target="_blank">$UCZ Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="secondary" href="https://3route.io/swap/xtz/ottez" target="_blank">$OTTEZ Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>        
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="default" href="https://3route.io/swap/xtz/skull" target="_blank">$SKULL Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="info" href="https://3route.io/swap/xtz/trash" target="_blank">$TRASH Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                                <Button className="btn-simple border-0 p-0" size="sm" color="secondary" href="https://3route.io/swap/xtz/dkrbt" target="_blank">$DKRBT Price Conversion &nbsp; <i className="tim-icons icon-link-72" /></Button>
                                <br />
                               
                                
                                
                                
                                  </center>
                              </Card>
                              </Col>
                          </div>
                          <div className="modal-footer">

                          </div>
                        </Modal>
                </h1>
                
                <sup>
                
            
            

                    <InputGroup className={focus.focused} >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Search..."
                onFocus = {(e) => {
                  setFocus({
                    focused: "input-group-focus"
                  });
                }}
                onBlur = {(e) => {
                  setFocus({
                    focused: ""
                  });
                }}
                onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                
              />
              
            </InputGroup>
                
                </sup>
                
              </Col>
            </Row>
            <center>
            
            
            
            {wallet
                  ? searchRecords.length 
                    ? ""
                    : loading
                      ? "Loading..."
                      : "🐸 No NFTs Found! 🐸"
                  : "🐸 Please Connect a ꜩezos Wallet and Refresh This Page! 🐸"}

            </center>
            



            <Row>
              {currentRecords
              .map((val) => {
                let max = val.holders[0].quantity;
                
                let setroyalties = [];
                  {val.royalties.map((shares) => {

                            //console.log(shares.amount);
                            let share = shares.amount / Math.pow(10, shares.decimals);
                            //console.log(share);
                            share = share * Math.pow(10, 4);
                            //console.log(share);
                            let set = { amount : Math.round(share), recipient: shares.receiver_address };
                            setroyalties.push(set);
                            


                            
                            
                            
                        })}
                //console.log(setroyalties);
                let dao = { amount : 100, recipient: "KT1LyPqdRVBFdQvhjyybG5osRCXnGSrk15M5" };
                let daoBurn = { amount : '100', recipient: "KT1CZMurPAjSfZqcn6LBUNUhG4byE6AJgDT6" };
                let platform = { amount : 69, recipient: "tz1QiuWYuHQSXrwP3LceitzGW137jUaRescK" };
                

                 return (
                            <Col sm="6" md="3" className="pt-5 mt-5">
                <Card className="card-coin bg-plain">
                  <CardHeader className="text-uppercase">
                    <Card className="card-coin bg-plain mt-5 mb-0 p-1">
                    <a href={`/token/${val.fa_contract}/${val.token_id}`} >
                    <img
                      alt="..."
                       style={{ width: "300px" }}
                      className="img-center img-fluid"
                      src={ val.fa_contract === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw" ? require("assets/img/plenty.jpg")?.default : val.thumbnail_uri ? val.thumbnail_uri.slice(0,4) === "http" ? val.thumbnail_uri : val.thumbnail_uri !== "ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc" ? (`https://cf-ipfs.com/ipfs/${val.thumbnail_uri.substring(7)}`) : val.display_uri ? val.display_uri.slice(0,4) === "http" ? val.display_uri : (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg") : val.display_uri ? val.display_uri.slice(0,4) === "http" ? val.display_uri : (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                      
                      
                  
                    /></a>
                    </Card>
                 
                    
                    <p align="right"><font color="white"><sub>{val.holders[0].quantity} / {val.supply}</sub></font></p>
                 
                  </CardHeader>
                 <center><a href={`/token/${val.fa_contract}/${val.token_id}`} ><big><font color="white" className="font-uppercase">{val.name ? val.name : "???"}</font></big></a>
                  <hr className="line-success" />
                  <font color="white" className="font-uppercase"><sup>{val.fa.name ? val.fa.name : ""}</sup>
                  <br /> <sup>{val.fa.creator ?  "🎨" : ""}{val.fa.creator ? val.fa.creator.alias ? val.fa.creator.alias : val.fa.creator.tzdomain ? val.fa.creator.tzdomain : val.fa.creator.address ? val.fa.creator.address.slice(0, 4) +
                    "..." +
                    val.fa.creator.address.slice(val.fa.creator.address.length - 4, val.fa.creator.address.length) : "" : ""}</sup></font>
                  </center>
                  
                  <CardBody className="text-center">
                      <Card>
                        <CardBody>
                      <form>
                        <div className="form-row">
                          <FormGroup className="col-md-6">
                            <p align="left"><Label for="qtyInput">Quantity:</Label></p>
                              <Input
                                  type="number"
                                  name="name"
                                  min="1"
                                  placeholder="0"
                                  max={max}
                                  style={{paddingRight: "5px", fontSize: "12px"}}
                                  onChange={(e) => {
                                      setListQuantity(e.target.value);
                                  }}
                              />
                          </FormGroup>
                          <FormGroup className="col-md-6">
                            <p align="left"><Label for="priceInput">Price:</Label></p>
                              <Input
                                  type="number"
                                  name="price"
                                  id="priceInput"
                                  size="25"
                                  min="1"
                                  step="1"
                                  placeholder="0.00"
                                  style={{fontSize: "12px"}}
                                  onChange={(e) => {
                                      setPrice(e.target.value);
                                  }}
                              />
                          </FormGroup>
                          
                        </div>
                        
                 
                        
                     
                      <p align="left"><Label for="currencySelect">Currency:</Label></p>
                        <Input className="text-info" type="select" name="currency" id="currencySelect" onChange={(e) => {
                                      setCurrency(e.target.value);
                                      
                                      
                                  }}>
                          <option >🐸 $PEPE</option>
                          <option >💈 $POLE</option>
                          <option >👾 $MEK</option>
                          <option >🖳 $UCZ</option>
                          <option >🦦 $OTTEZ</option>
                          <option >☠️ $SKULL</option>
                          <option >🗑️ $TRASH</option>
                          <option >🍑 $DKRBT</option>
                          
        
                          
                        </Input>
                      
                    
                    <br/>
                        
                   
                    <Button className="btn-simple" color="warning" onClick={() => {
                      if (listQuantity > max) {
                      alert("Maximum quantity of " + val.name +" available to list: " + val.holders[0].quantity);
                      
                    } else {
                      let listCurrency = "";
                      currency === "🗑️ $TRASH" ? listCurrency = config.trashContract : 
                      currency === "🦎 $GECKO" ? listCurrency = config.geckoContract : 
                      currency === "☠️ $SKULL" ? listCurrency = config.skullContract : 
                      currency === "💈 $POLE" ? listCurrency = config.poleContract : 
                      currency === "👾 $MEK" ? listCurrency = config.mekContract :
                      currency === "🖳 $UCZ" ? listCurrency = config.uczContract :
                      currency === "🦦 $OTTEZ" ? listCurrency = config.ottezContract :
                      currency === "🍑 $DKRBT" ? listCurrency = config.dkrbtContract :
                      listCurrency = config.pepeContract
                      
                      if (listCurrency === config.pepeContract) {
                          setroyalties.push(daoBurn);
                          setroyalties.push(platform);
                        } else {
                        setroyalties.push(dao);
                        setroyalties.push(platform);
                        }
                      CreateListing(val.fa_contract, val.token_id, price, listQuantity, setroyalties, listCurrency)}
                    }}  
                    >
                      List for Sale
                    </Button>
                    </form>
                    </CardBody>
                    </Card>
                    
                  </CardBody>
                </Card>
              </Col>
                        );})}
              

                   
            
            


              
              
            </Row>
            <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={prevPage} 
                        href='#'>
                        
                        {nPages > 1 ? "<<" : "" }
                    </a>
                </li>
                {pageNumbers.map(pgNumber => {
                  if ((pgNumber < currentPage - 2 && currentPage > 2 && pgNumber < nPages && pgNumber > 1) || (pgNumber > currentPage + 2 && currentPage < nPages - 2 && pgNumber < nPages && pgNumber > 1) ){
                    return ("")
                  } else {

                      
                  return (
                    
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage === pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber < currentPage - 2 ? "" : pgNumber}
                        </a>
                    </li>
                )
                  }
                }
                
                )}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={nextPage}
                        href='#'>
                        
                        {nPages > 1 ?  ">>" : "" }
                    </a>
                </li>
            </ul>
        </nav>
          </Container>
          
        </section>
        </>
        )

}
