import React, {useEffect, useRef, useState} from "react";
import { useQuery } from "@apollo/client";
import Countdown from 'react-countdown';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Col
} from "reactstrap";
import { Purchase } from "../utils/wallet";
import { ALL_AUCTIONS } from "utils/GetAuctions";

export default function Auctions() {

const [search, setSearch] = useState('');
const [focus, setFocus] = useState( {
      focused: ""
    });
let searchRecords = [];

const {data} = useQuery(ALL_AUCTIONS, {pollInterval: 3000,});
const [tokenName, settokenName] = useState([]);

const [loading, setLoading] = useState(true);
const [bidAmount, setBidAmount] = useState("0");

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(16);
const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;


useEffect(() => {
      if (data) {
          settokenName(data.english_auction_active);
          setLoading(false);
          
      }
  }, [data]);

tokenName.filter((item) => {

                return item
                      ? search.toLowerCase() === '' 
                          ? item
                          : JSON.stringify(item).toLowerCase().includes(search) 
                      : ""
                    }
                )
                
                .map((item) => (

                 searchRecords.push(item)
                ))

const currentRecords = searchRecords.slice(indexOfFirstRecord, 
                                    indexOfLastRecord);
const nPages = Math.ceil(searchRecords.length / recordsPerPage)
const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
const renderer = ({ days, hours, minutes, seconds, completed }) => {
                    if (completed) {
                      // Render a completed state
                      return "Ended";
                    } else {
                      // Render a countdown
                      return <span><b>{days}<sub><sub>d</sub></sub> {hours}<sub><sub>h</sub></sub> {minutes}<sub><sub>m</sub></sub> {seconds}<sub><sub>s</sub></sub></b></span>;
                    }
                  };

  return(
    <>
    
        <div id="auctions"></div>
        <section >
          <Container className="mt-5">
            <Row>
              <Col md="6">
                <hr className="line-info" />
                <h1>
                  All{" "}
                  <span className="text-info">Auctions</span>
                </h1>
                <sup>
                
            
            

                    <InputGroup className={focus.focused} >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Search..."
                onFocus = {(e) => {
                  setFocus({
                    focused: "input-group-focus"
                  });
                }}
                onBlur = {(e) => {
                  setFocus({
                    focused: ""
                  });
                }}
                onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                
              />
              
            </InputGroup>
                
                </sup>
              </Col>
            </Row>

            <center>
            { searchRecords.length 
              ? "" 
              : loading 
                ? "Loading..."
                : "🐸 No Listings Found! 🐸"
              }
            </center>
            



            <Row>
              {currentRecords.map((val) => {
                
                var date = new Date(val.end_time.slice(0, -6))
                var userTimezoneOffset = date.getTimezoneOffset() * 60000;
              

                 return (
                            <Col sm="6" md="3" className="pt-5 mt-5">
                <Card className="card-coin bg-plain">
                  <CardHeader className="text-uppercase">
                    <Card className="card-coin bg-plain mt-5 mb-0 p-1">
                    <a href={`/token/${val.token.fa_contract}/${val.token.token_id}`}>
                    <img
                      alt="..."
                      style={{ width: "300px" }}
                      className="img-center img-fluid"
                      src={ val.token.fa_contract === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw" ? require("assets/img/plenty.jpg")?.default : val.token.thumbnail_uri ? val.token.thumbnail_uri.slice(0,4) === "http" ? val.token.thumbnail_uri : val.token.thumbnail_uri != "ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc" ? (`https://cf-ipfs.com/ipfs/${val.token.thumbnail_uri.substring(7)}`) : val.token.display_uri ? val.token.display_uri.slice(0,4) === "http" ? val.token.display_uri : (`https://cf-ipfs.com/ipfs/${val.token.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg") : val.token.display_uri ? val.token.display_uri.slice(0,4) === "http" ? val.token.display_uri : (`https://cf-ipfs.com/ipfs/${val.token.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                    /></a>
                    </Card>
                 
                    
                    <p align="center"><font color="white" size="4">
                      

            <Countdown 
            date={date.getTime() - userTimezoneOffset } 
            renderer={renderer}
            />
      </font></p>
                 
                  </CardHeader>
                  <center><a href={`/token/${val.token.fa_contract}/${val.token.token_id}`} ><big><font color="white" className="font-uppercase">{val.token.name ? val.token.name : "???"}</font></big></a>
                  <br />
                  <font color="white" className="font-uppercase"><sup>{val.token.fa.name ? val.token.fa.name : ""}</sup>
                  <br /> <sup>{val.token.fa.creator ?  "🎨" : ""}{val.token.fa.creator ? val.token.fa.creator.alias ? val.token.fa.creator.alias : val.token.fa.creator.tzdomain ? val.token.fa.creator.tzdomain : val.token.fa.creator.address ? val.token.fa.creator.address.slice(0, 4) +
                    "..." +
                    val.token.fa.creator.address.slice(val.token.fa.creator.address.length - 4, val.token.fa.creator.address.length) : "" : ""}</sup>
                  <hr className={val.currency.fa_contract == "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9" ? ("line-success") : val.currency.fa_contract == "KT1ShjB24tTXX8m5oDCrBHXqynCpmNJDxpD5" ? ("line-info") : ("line-primary") }/>                   
                    <h4>
                    {val.bids.length ? "Highest Bid: " + val.highest_bid / 1000000 + " xtz": ""}
                    {val.bids.length ? "" : "Reserve: "  + val.reserve / 1000000 + " xtz" }
                    <br/>
                    <sup><sup>Minimum Bid: {val.bids.length ? (val.highest_bid + val.price_increment)/ 1000000 + " xtz" : val.reserve / 1000000 + " xtz"}</sup></sup>
                    </h4>
                    
                    </font>
                  </center>
                  
                  <CardBody className="text-center p-2">
                    
                    
                    <form>
                        <div className="form-row m-0 p-0">
                          <FormGroup className="col-2 m-0 p-0">

                          </FormGroup>
                          <FormGroup className="col-8 m-0 p-0">
                            
                          </FormGroup>
                          <FormGroup className="col-2 m-0 p-0">

                          </FormGroup>
                          </div>
                          <div className="form-row m-0 p-0">
                        <FormGroup className="col-1 m-0 p-0"></FormGroup>  
                        <FormGroup className="col-5 m-0 p-0">
                            
                              <Input
                                  type="number"
                                  name="name"
                                  min={val.bids.length ? (val.highest_bid + val.price_increment) / 1000000 : val.reserve / 1000000 }
                                  placeholder={val.bids.length ? (val.highest_bid + val.price_increment)  / 1000000 : val.reserve / 1000000}
                                  
                                  style={{ paddingRight: "5px", fontSize: "12px"}}
                                  onChange={(e) => {
                                      setBidAmount(e.target.value);
                                  }}
                              /> 
                         </FormGroup>     
                        <FormGroup className="col-6 ">
                        <Button className="btn-simple p-2 m-0" color={val.currency.fa_contract == "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9" ? "success" : val.currency.fa_contract == "KT1ShjB24tTXX8m5oDCrBHXqynCpmNJDxpD5" ? "info" : "primary"} onClick={() => Purchase(1, val.bigmap_key, val.currency.fa_contract, val.price)}
                        >
                          Place Bid
                        </Button>
                        </FormGroup>
                        
                    </div>
                    </form> 
                    
                    
                  </CardBody>
                </Card>
              </Col>
                        );})}
              

                   
            
            


              
              
            </Row>
            <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={prevPage} 
                        href='#'>
                        
                        {nPages > 1 ? "<<" : "" }
                    </a>
                </li>
                {pageNumbers.map(pgNumber => {
                  if ((pgNumber < currentPage - 1 && currentPage > 2 && pgNumber < nPages && pgNumber > 1) || (pgNumber > currentPage + 1 && currentPage < nPages - 2 && pgNumber < nPages && pgNumber > 1) ){
                    return (".")
                  } else {

                      
                  return (
                    
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                )
                  }
                }
                
                )}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={nextPage}
                        href='#'>
                        
                        {nPages > 1 ?  ">" : "" }
                    </a>
                </li>
              
            </ul>
        </nav>
          </Container>
          
        </section>

        </>
        )

}
