import React, {useEffect, useState} from "react";
import {gql} from '@apollo/client';
import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import { useParams } from "react-router-dom";
import { Purchase } from "utils/wallet";
import config from "../config"

export default function TokenPage() {  
    React.useEffect(() => {
    document.body.classList.toggle("index-page");
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
    }, []);
  
    let { contract } = useParams();
    let { tokenID } = useParams();
    
    const LOAD_TOKEN = gql `
    query MyQuery {
    token(
        where: {fa_contract: {_eq: "${contract}"}, token_id: {_eq: "${tokenID}"}}
    ) {
        artifact_uri
        display_uri
        name
        description
        supply
        thumbnail_uri
        mime
        fa {
        name
        creator {
            address
            alias
            tzdomain
        }
        }
        creators {
        creator_address
        }
        holders(where: {quantity: {_gt: "0"}}) {
        quantity
        holder_address
        }
    }
    listing(
        where: {currency: {fa_contract: {_in: [${config.supportedTokens}]}}, status: {_eq: "active"}, shares: {_is_null: false}, fa_contract: {_eq: "${contract}"}, token: {token_id: {_eq: "${tokenID}"}}}
        order_by: {price: asc}
    ) {
        id
        bigmap_key
        status
        amount_left
        price
        shares
        level
        currency {
        fa_contract
        decimals
        }
        token {
        fa {
            name
            creator {
            address
            alias
            tzdomain
            }
        }
        supply
        artifact_uri
        display_uri
        thumbnail_uri
        name
        description
        fa_contract
        token_id
        royalties {
            receiver_address
            amount
            decimals
        }
        }
         seller {
            alias
            address
            tzdomain
        }
    }
    }
    `


    
    const {data} = useQuery(LOAD_TOKEN);
    const [tokenName, settokenName] = useState([]);
    const [listingsList, setListingsList] = useState([]);
    const [loading, setLoading] = useState(true);
    let searchRecords = [];
    const [search, setSearch] = useState('');
    useEffect(() => {
        if (data) {
          settokenName(data.token);
          setListingsList(data.listing);
          setLoading(false);
        } 
    }, [data]);


    listingsList.filter((item) => {

                let setroyalties = [];
                let setroyaltiesOld = [];
                let setroyaltiesBurn = [];
                
                  {item.token.royalties.map((shares) => {
                            
                            let share = shares.amount / Math.pow(10, shares.decimals);
                            
                            share = share * Math.pow(10, 4);
                            
                            share = Math.round(share);
                            let set = { amount : share.toString(), recipient: shares.receiver_address };
                            setroyalties.push(set);
                            setroyaltiesOld.push(set);
                            setroyaltiesBurn.push(set);

                        })}
                
                let dao = { amount : '100', recipient: "KT1LyPqdRVBFdQvhjyybG5osRCXnGSrk15M5" };
                let dao2 = { amount : '100', recipient: "KT1CZMurPAjSfZqcn6LBUNUhG4byE6AJgDT6" };
                let platform = { amount : '69', recipient: "tz1QiuWYuHQSXrwP3LceitzGW137jUaRescK" };
                let platform2 = { amount : '70', recipient: "tz1QiuWYuHQSXrwP3LceitzGW137jUaRescK" };
                setroyalties.push(dao);
                setroyalties.push(platform);
                setroyaltiesOld.push(dao);
                setroyaltiesOld.push(platform2);
                setroyaltiesBurn.push(dao2);
                setroyaltiesBurn.push(platform);
                
                return item
                    ? JSON.stringify(item.shares) == JSON.stringify(setroyalties) || JSON.stringify(item.shares) == JSON.stringify(setroyaltiesOld) || JSON.stringify(item.shares) == JSON.stringify(setroyaltiesBurn)
                      ? search.toLowerCase() === '' 
                          ? item
                          : JSON.stringify(item).toLowerCase().includes(search) 
                      : ""
                    : ""}
                )
                
                .map((item) => (

                 searchRecords.push(item)
                ))

  return (
    <> 
                   <center>
            { tokenName.length 
              ? "" 
              : loading 
                ?   <Container className="pt-5 mt-5">
                        <Row className="pt-0 mt-0">
                            <Card className="card-stats bg-plain px-2 mb-5">
                                <CardBody>Loading...</CardBody>
                            </Card>
                        </Row>
                    </Container>
                : "🐸 Token Not Found! 🐸"
              }
            
            </center>
    
        {tokenName.map((val) => {

            
            return (
            <>
            <Container className="pt-5 mt-5">
               
                <Row className="pt-0 mt-0">
                    <Card className="card-stats bg-plain px-2 mb-5">
                        <CardBody>
              
                            <Row className="row-grid justify-content-between align-items-top pt-3 ">
                                <Col lg="6" sm="12" md="6">
                                <center>
                                { val.mime === "video/mp4" || val.mime === "video/quicktime"  ? 
                                <><video controls className="img-fluid" preload="auto" loop="loop" autoplay="false" muted="muted" volume="0"><source src={`https://cf-ipfs.com/ipfs/${val.artifact_uri.substring(7)}`} type="video/mp4" />Video not supported</video>
                                </>
                                
                                : val.mime === "audio/vnd.wave" ?
                                <><img
                                alt="..."
                                className="img-fluid"
                                src={ val.display_uri ? (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : val.thumbnail_uri ? (`https://cf-ipfs.com/ipfs/${val.thumbnail_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                                />
                                <br /><br />
                                <audio controls src={`https://cf-ipfs.com/ipfs/${val.artifact_uri.substring(7)}`}></audio>
                                </>

                                : val.mime === "audio/mpeg" ?
                                <><img
                                alt="..."
                                className="img-fluid"
                                src={ val.display_uri ? (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : val.thumbnail_uri ? (`https://cf-ipfs.com/ipfs/${val.thumbnail_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                                />
                                <br /><br />
                                <audio controls src={`https://cf-ipfs.com/ipfs/${val.artifact_uri.substring(7)}`}></audio>
                                </>
                                :
                                <><img
                                alt="..."
                                className="img-fluid"
                                src={ val.display_uri ? (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : val.thumbnail_uri ? (`https://cf-ipfs.com/ipfs/${val.thumbnail_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                                />
                                </>}
                                
                                </center>
                                <p align="right"><font color="white"><sub>1 / {val.supply}</sub></font></p>
                                </Col>
                                <Col lg="6" sm="12" md="6">
                                <Row className="row-grid justify-content-between align-items-bottom pt-3 ">
                                    <Col lg="12" sm="12" md="12">
                                <center><a href={`https://objkt.com/asset/${contract}/${tokenID}`} target="_new"><h2>{val.name ? val.name : "???"}
                                <hr className="line-info" align="right" />
                                </h2></a>
                                
                                <font color="white" className="font-uppercase">{val.fa.name ? val.fa.name : ""}
                                <br /> {val.fa.creator ?  "🎨" : ""}{val.fa.creator ? val.fa.creator.alias ? val.fa.creator.alias : val.fa.creator.tzdomain ? val.fa.creator.tzdomain : val.fa.creator.address ? val.fa.creator.address.slice(0, 4) + "..." + val.fa.creator.address.slice(val.fa.creator.address.length - 4, val.fa.creator.address.length) : "" : ""}</font>
                                <br />
                                <hr className="line-default" align="right" />
                                {val.description}
                               
                                </center>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                <Card>
                                    <CardBody>
                                        <h4>
                                        Active{" "}
                                        <span className="text-info">Listings</span>
                                        <hr className="line-info" />
                                        </h4>
                                        { listingsList.length 
                                            ? "" 
                                            : loading 
                                                ? "Loading..."
                                                : <center>🐸 No Listings Found! 🐸</center> }


                                    {searchRecords.map((list) => {

                                    return (
                                         <Row >
                                        <Col className="text-right pt-1" xs="6" lg="6" sm="6" md="6">
                                        { list.seller.alias ? list.seller.alias : list.seller.tzdomain ? list.seller.tzdomain : list.seller.address.slice(0, 4) +
                    "..." +
                    list.seller.address.slice(list.seller.address.length - 4, list.seller.address.length) }
                                        {' '}x{' '}{' '}{' '} 
                                        { list.amount_left}
                                        <br />
                                        <sup>@ {
                                        list.currency.fa_contract === config.poleContract ? (list.price / 100000000) + " 💈 $POLE" :
                                        list.currency.fa_contract === config.mekContract ? (list.price / 100000) + " 👾 $MEK" :
                                        list.currency.fa_contract === config.uczContract ? (list.price / 100000000) + " 🖳 $UCZ" :
                                        list.currency.fa_contract === config.ottezContract ? (list.price / 100000000) + " 🦦 $OTTEZ" :
                                        list.currency.fa_contract === config.skullContract ? (list.price / 1000) + " ☠️ $SKULL" :
                                        list.currency.fa_contract === config.trashContract ? (list.price / 100000000) + " 🗑️ $TRASH" : 
                                        list.currency.fa_contract === config.dkrbtContract ? (list.price / 100) + " 🍑 $DKRBT" : 
                                        (list.price / 100) + " 🐸 $PEPE"}{" each"}</sup>
                                        </Col>
                                        <Col xs="6" lg="6" sm="6" md="6">
                                            
                                        <Button className="btn-simple" color={
                                            list.currency.fa_contract === config.poleContract ? "neutral" :
                                            list.currency.fa_contract === config.mekContract ? "danger" : 
                                            list.currency.fa_contract === config.uczContract ? "primary" : 
                                            list.currency.fa_contract === config.ottezContract ? "secondary" :
                                            list.currency.fa_contract === config.skullContract ? "default" : 
                                            list.currency.fa_contract === config.trashContract ? "info" : 
                                            list.currency.fa_contract === config.dkrbtContract ? "secondary" : 
                                            "success" }  
                                            
                                            onClick={() => Purchase(1, list.bigmap_key, list.currency.fa_contract, list.price)}
                                            >
                                            {
                                            list.currency.fa_contract === config.poleContract ? (list.price / 100000000) + " 💈 $POLE" :
                                            list.currency.fa_contract === config.mekContract ? (list.price / 100000) + " 👾 $MEK" :
                                            list.currency.fa_contract === config.uczContract ? (list.price / 100000000) + " 🖳 $UCZ" :
                                            list.currency.fa_contract === config.ottezContract ? (list.price / 100000000) + " 🦦 $OTTEZ" :
                                            list.currency.fa_contract === config.skullContract ? (list.price / 1000) + " ☠️ $SKULL" :
                                            list.currency.fa_contract === config.trashContract ? (list.price / 100000000) + " 🗑️ $TRASH" : 
                                            list.currency.fa_contract === config.dkrbtContract ? (list.price / 100) + " 🍑 $DKRBT" :
                                            (list.price / 100) + " 🐸 $PEPE"}
                                          </Button>
                                        </Col>
                                        </Row>
                                    );
                                    })
                                    }
                                    </CardBody>
                                </Card>
                                </Row>
                                </Col>
                            </Row> 
                        </CardBody>
                    </Card>
                </Row>
            </Container>
            </>
            );
        })}
        
    </>
  );
}
