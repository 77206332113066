
import React from "react";
import {
  Container
} from "reactstrap";


export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        
            <center><font color="white">
              <a      href="https://discord.gg/HaXyXgqbBM"
                rel="noopener noreferrer"
                target="_blank"
                title="Join ꜩezos Pepe 🐸 on Discord"
              >
                <i className="fab fa-discord fa-flip" style={{
          color: "#ffffff"
        }}/>
             &nbsp; &nbsp;  
        </a>

        <a      href="https://twitter.com/pepeetnunc"
                rel="noopener noreferrer"
                target="_blank"
                title="Follow pepe et nunc 🐸 on Twitter"
              >
                <i className="fab fa-twitter fa-shake" style={{
          color: "#ffffff"
        }} />
        &nbsp; &nbsp;
        </a>

        <a      href="https://tezospepe.com"
                rel="noopener noreferrer"
                target="_blank"
                title="Discover ꜩezos Pepe 🐸"
              >
              <i className="fas fa-frog fa-bounce" style={{
          color: "#ffffff"
        }} />
             &nbsp; &nbsp;  
        </a>
        
        <a      href="https://twitter.com/_ryxns"
                rel="noopener noreferrer"
                target="_blank"
                title="Made with 💚 by ryxns"
              >
                <i className="fas fa-heart fa-beat-fade" style={{
          color: "#ffffff"
        }} />
        </a>
        </font>  
      </center>
      </Container>
    </footer>
  );
}
