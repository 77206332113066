import React from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";
import GetNewListings from './NewListings';
import GetFeatured from "./Featured";


export default function Index() {  
  
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);


  return (
    <> 
      <div className="wrapper">
        <div className="page-header header-filter">
          <div className="squares square1" />
          <div className="squares square2" />
          <div className="squares square3" />
          <div className="squares square4" />
          <div className="squares square5" />
          <div className="squares square6" />
          <div className="squares square7" />
            <Container className="pt-5">
            
              <Row className="row-grid justify-content-between align-items-center text-left pt-5 ">
                <Col lg="4" sm="12" md="4">
                  <div>
                    <h4 className="text-white">
                    
                    Swap NFTs using ꜩ <span className="text-success">$PEPE</span>, $POLE, <span className="text-danger">$MEK</span>, <span className="text-primary">$UCZ</span>, <span className="text-secondary">$OTTEZ</span> , <span className="text-default">$SKULL</span>, <span className="text-info">$TRASH</span> & <span className="text-secondary">$DKRBT</span>
                    <br />
                    <small><small>Auctions, Trade-In's, Burn-to-Redeem & More Coming Soon!</small></small>
                    <br />
                    <sup><small><small>
                      <font color="white">Powered by </font><a href="https://objkt.com" target="_blank" rel="noreferrer"><font color="white">objkt.com</font></a>
                    </small></small></sup>
                    </h4>
                  </div>
                </Col>
                <Col>
                  <center>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/pepe-lay.png")?.default}
                    style={{ width: "500px" }}
                  />
                  </center>
                </Col>
              </Row>
              <Row className="pt-0">
                <Card className="card-stats bg-plain px-2">
                  <CardBody>
                    <GetFeatured />  
                  </CardBody>
                </Card>
              </Row>
            </Container>
        </div>
      </div>
         
    </>
  );
}
