import React, {useEffect, useState} from "react";
import { useQuery } from "@apollo/client";
import {gql} from '@apollo/client'
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  connectWallet,
  getActiveAccount,
  disconnectWallet,
} from "../../utils/wallet";
import config from "../../config"


export default function IndexNavbar() {
  const [wallet, setWallet] = useState(null);

  const handleConnectWallet = async () => {
    const { wallet } = await connectWallet();
    setWallet(wallet);
  };
  const handleDisconnectWallet = async () => {
    const { wallet } = await disconnectWallet();
    setWallet(wallet);
  };

  useEffect(() => {
    const func = async () => {
      const account = await getActiveAccount();
      
      if (account) {
        setWallet(account.address);
        
      }
    };
    func();
  }, []);
    
  const USER_INFO = gql `
    query MyQuery {
      holder(where: {address: {_eq: "${wallet}"}}) {
        alias
        logo
        tzdomain
      }
    }
  `
  const {data} = useQuery(USER_INFO);
    useEffect(() => {
      if (data) {
          setuserInfo(data.holder);   
      }
  }, [data]);
  const [userInfo, setuserInfo] = useState([]);
  const [pepeBalance, setPepeBalance] = React.useState("");
  const [trashBalance, setTrashBalance] = React.useState("");
  const [mekBalance, setMekBalance] = React.useState("");
  const [uczBalance, setUczBalance] = React.useState("");
  const [ottezBalance, setOttezBalance] = React.useState("");
  const [skullBalance, setSkullBalance] = React.useState("");
  const [poleBalance, setPoleBalance] = React.useState("");
  const [dkrbtBalance, setDkrbtBalance] = React.useState("");

  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");

  async function logJSONData() {
  const pepebalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.pepeContract}&token.tokenId=0&select=balance`);
  const pepebalData = await pepebalresponse.json();
  setPepeBalance(pepebalData.toString());
  
  const trashbalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.trashContract}&token.tokenId=0&select=balance`);
  const trashbalData = await trashbalresponse.json();
  setTrashBalance(trashbalData.toString());
  
  const mekbalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.mekContract}&token.tokenId=0&select=balance`);
  const mekbalData = await mekbalresponse.json();
  setMekBalance(mekbalData.toString());
  
  const skullbalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.skullContract}&token.tokenId=0&select=balance`);
  const skullbalData = await skullbalresponse.json();
  setSkullBalance(skullbalData.toString());
  
  const polebalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.poleContract}&token.tokenId=0&select=balance`);
  const polebalData = await polebalresponse.json();
  setPoleBalance(polebalData.toString());
  
  const uczbalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.uczContract}&token.tokenId=0&select=balance`);
  const uczbalData = await uczbalresponse.json();
  setUczBalance(uczbalData.toString());
 
  const ottezbalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.ottezContract}&token.tokenId=0&select=balance`);
  const ottezbalData = await ottezbalresponse.json();
  setOttezBalance(ottezbalData.toString());
  
  const dkrbtbalresponse = await fetch(`https://api.tzkt.io/v1/tokens/balances?account=${wallet}&token.contract=${config.dkrbtContract}&token.tokenId=0&select=balance`);
  const dkrbtbalData = await dkrbtbalresponse.json();
  setDkrbtBalance(dkrbtbalData.toString());

  
}

  
  
  React.useEffect(() => {
    
    

    window.addEventListener("scroll", changeColor);
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);
  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-success");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };


  logJSONData();

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      
      <Container>
      
        <div className="navbar-translate">
          <Row >
            <Col lg="12" sm="12" md="12"  >
           
            <a href="/"><img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/pepe_logo.png")?.default}
                    style={{ width: "30px" }}
                  /><font color="white"> pepe et nunc</font></a>
            </Col>
          </Row>
            
           
          
          
          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar1" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <p className="text-info"><a href="/"><font color="white">🐸 pepe et nunc</font></a></p>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
        

              <NavItem>
              <Button
                className="btn-simple border-0 mb-1 ml-0 mr-0 px-4 py-2"
                style={{
          "color": "#ffffff"
        }}
                href="/dkrbt"  >
                🍑 $DKRBT Event
              </Button>
              <Button
                className="btn-simple border-0 mb-1 ml-0 mr-0 px-0 py-2"
                style={{
          "color": "#ffffff"
        }}
                href="/listings"  >
                <i className="tim-icons icon-components" />{" "}
                Explore All
              </Button>
              </NavItem>
              {wallet ? <>

            <UncontrolledDropdown nav>
              <DropdownToggle
                className="px-0 py-0"
                caret
                color="default"
                data-toggle="dropdown"
                href="/profile"
                nav
                onClick={(e) => e.preventDefault()}
              >
                {userInfo.map((val) => {
                  return (
                    val.logo ? <img src={`${val.logo}`} className="img-fluid rounded-circle shadow-lg" width="40" alt="..."/> : <i className="tim-icons icon-single-02 mt-1" style={{"font-size": "2.5em"}} />
                  )
                })}
    

                
              </DropdownToggle>
              <DropdownMenu className="dropdown-with-icons dropdown-black p-2">
                
                <h6><center>{userInfo.map((val) => {
    return(
      val.alias ? val.alias : val.domain ? val.domain : wallet.slice(0, 4) + "..." + wallet.slice(wallet.length - 4, wallet.length)
      )
  }
  )
                  }
                  </center></h6>
                  
                <DropdownItem divider className="p-0"/>
                <DropdownItem href="/create">
                  <i className="tim-icons icon-simple-add" />
                  Create Listing
                </DropdownItem>
                <DropdownItem href="/profile">
                  <i className="tim-icons icon-settings" />
                  Your Profile
                </DropdownItem>
                <DropdownItem divider className="p-0 m-2"/>
                <center>
                  <h6>Wallet Balances</h6>
                <DropdownItem divider className="p-0 m-2"/>
                </center>
                
                <p align="right">
                  
                  <b>
                    <a href="https://pepeswap.tez.page/" target="_blank" rel="noreferrer"><span className="text-success">{ wallet ? (pepeBalance / 100).toFixed(2) + " " : "0.00"}&nbsp;🐸</span></a>
                      <br/>
                    <a href="https://3route.io/swap/xtz/pole" target="_blank" rel="noreferrer"><span className="text-white">{ wallet ? (poleBalance / 100000000).toFixed(2) + " " : "0.00"}&nbsp;💈&nbsp;</span></a>
                      <br/>
                    <a href="https://3route.io/swap/xtz/mek" target="_blank" rel="noreferrer"><span className="text-danger">{ wallet ? (mekBalance / 100000).toFixed(2) + " " : "0.00"}&nbsp;👾</span></a>
                      <br/>
                    <a href="https://3route.io/swap/xtz/ucz" target="_blank" rel="noreferrer"><span className="text-primary">{ wallet ? (uczBalance / 100000000).toFixed(2) + " " : "0.00"}&nbsp;🖳&nbsp;</span></a>
                      <br/>
                      <a href="https://3route.io/swap/xtz/ottez" target="_blank" rel="noreferrer"><span className="text-secondary">{ wallet ? (ottezBalance / 100000000).toFixed(2) + " " : "0.00"}&nbsp;🦦</span></a>
                      <br/>
                    <a href="https://3route.io/swap/xtz/skull" target="_blank" rel="noreferrer"><span className="text-default">{ wallet ? (skullBalance / 1000).toFixed(2) + " " : "0.00"}&nbsp;☠️</span></a>
                      <br/>
                    <a href="https://3route.io/swap/xtz/trash" target="_blank" rel="noreferrer"><span className="text-info">{ wallet ? (trashBalance / 100000000).toFixed(2) + " " : "0.00"}&nbsp;🗑️</span></a>
                      <br/>
                    <a href="https://3route.io/swap/xtz/dkrbt" target="_blank" rel="noreferrer"><span className="text-info">{ wallet ? (dkrbtBalance / 100).toFixed(2) + " " : "0.00"}&nbsp;🍑</span></a>
                
                </b>
                </p>
                
                <center><h6><sub>Click Balance to Swap</sub></h6></center>
                
                <DropdownItem divider className="p-0"/>
                <DropdownItem href="/" onClick={wallet ? handleDisconnectWallet : handleConnectWallet}>
                  <i className="tim-icons icon-button-power" />
                  Disconnect
                </DropdownItem>
                
                
              </DropdownMenu>
            </UncontrolledDropdown>

            </>
            : 
            <NavItem>
              
                  <Button
                className="btn-simple border-0 mb-1 ml-0 mr-0 px-0 py-2"
                style={{
          "color": "#ffffff"
        }}
                onClick={wallet ? handleDisconnectWallet : handleConnectWallet} >
                <i className="tim-icons icon-wallet-43" />{" "}
                {wallet
                  ? wallet.slice(0, 4) +
                    "..." +
                    wallet.slice(wallet.length - 4, wallet.length)
                  : "Connect"}
              </Button>
                
              
              
            </NavItem>}

           

            
            
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
