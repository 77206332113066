import { TezosToolkit } from "@taquito/taquito";
import { BeaconWallet } from "@taquito/beacon-wallet";
import config from "../config";
//import { OpKind } from "@taquito/taquito";

const preferredNetwork = "mainnet";
const options = {
  name: "Pepe Et Nunc",
  iconUrl: "https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg",
  preferredNetwork: preferredNetwork,
};
const rpcURL = "https://mainnet.tezos.marigold.dev/";
const wallet = new BeaconWallet(options);

const getActiveAccount = async () => {
  return await wallet.client.getActiveAccount();
};

const connectWallet = async () => {
  let account = await wallet.client.getActiveAccount();

  if (!account) {
    await wallet.requestPermissions({
      network: { type: preferredNetwork },
    });
    account = await wallet.client.getActiveAccount();
  }
  return { success: true, wallet: account.address };
};

const disconnectWallet = async () => {
  await wallet.disconnect();
  return { success: true, wallet: null };
};

const checkIfWalletConnected = async (wallet) => {
  try {
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        type: { network: preferredNetwork },
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const Purchase = async (quantity, askID, listCurrency, price) => {
  
  const response = await checkIfWalletConnected(wallet);

  if (response.success) {
    const tezos = new TezosToolkit(rpcURL);
    tezos.setWalletProvider(wallet);


    const marketContract = await tezos.wallet.at(config.objktMarketAddress);
    const currencyContract = await tezos.wallet.at(listCurrency);
    const userAccount = await wallet.client.getActiveAccount();

    const batchOp = await tezos.wallet.batch()
      .withContractCall(currencyContract.methods.update_operators([
          {
              add_operator: {
                  owner: userAccount.address,
                  operator: marketContract.address,
                  token_id: 0
              }
          }
      ]))
      
      
      .withContractCall(marketContract.methods.fulfill_ask(askID))

      
      .withContractCall(currencyContract.methods.update_operators([
          {
              remove_operator: {
                  owner: userAccount.address,
                  operator: marketContract.address,
                  token_id: 0
              }
          }
      ]))
    
      .send();
      await batchOp.confirmation();
     
    /*
    let microTransactions = [];
    
    microTransactions.push({
        kind: OpKind.TRANSACTION,
        ...pepeContract.methods.update_operators([
            {
                add_operator: {
                    owner: userAccount.address,
                    operator: marketContract.address,
                    token_id: 0
                }
            }
        ])
    });
    for (let i = 0; i < quantity; i++) {
      microTransactions.push({
        kind: OpKind.TRANSACTION,
        ...marketContract.methods.fulfill_ask(2546897).toTransferParams(),
        amount: .77,
        mutez: false,
      });
    }
    microTransactions.push({
        kind: OpKind.TRANSACTION,
        ...pepeContract.methods.update_operators([
            {
                remove_operator: {
                    owner: userAccount.address,
                    operator: marketContract.address,
                    token_id: 0
                }
            }
        ])
    });
    console.log(microTransactions);
    const batch = await tezos.wallet.batch(microTransactions);
    const operation = await batch.send();
    await operation.confirmation();
  */
  }
};

export const CreateListing = async (fa2Contract, tokenID, price, listQuantity, setroyalties, listCurrency) => {
  let listPrice = 0;
  listCurrency === config.poleContract ? listPrice = price * 100000000 : 
  listCurrency === config.mekContract ? listPrice = price * 100000 : 
  listCurrency === config.uczContract ? listPrice = price * 100000000 : 
  listCurrency === config.ottezContract ? listPrice = price * 100000000 : 
  listCurrency === config.skullContract ? listPrice = price * 1000 :
  listCurrency === config.trashContract ? listPrice = price * 100000000 : 
  listPrice = price * 100;
  //console.log(setroyalties);
  //console.log(listPrice);
  const response = await checkIfWalletConnected(wallet);
 
  if (response.success) {
    const tezos = new TezosToolkit(rpcURL);
    tezos.setWalletProvider(wallet);
    const marketContract = await tezos.wallet.at(config.objktMarketAddress);
    const listingContract = await tezos.wallet.at(fa2Contract);
    const userAccount = await wallet.client.getActiveAccount();

    const batchOp = await tezos.wallet.batch()
      .withContractCall(listingContract.methods.update_operators([
            {
                add_operator: {
                    owner: userAccount.address,
                    operator: marketContract.address,
                    token_id: tokenID
                }
            }
        ]))
      .withContractCall(marketContract.methodsObject.ask({
        token: {
          address: fa2Contract,
          token_id: tokenID,
        },
        currency: { fa2: { address: listCurrency, token_id: 0 }},
        amount: listPrice,
        editions: listQuantity,
        shares: setroyalties,
        expiry_time: null,
        target: null,
      }))

      .send();
      
    //console.log(batchOp)
    await batchOp.confirmation();
};
}

export const CancelListing = async (fa2Contract, tokenID, askID) => {
  
  
  const response = await checkIfWalletConnected(wallet);

  if (response.success) {
    const tezos = new TezosToolkit(rpcURL);
    tezos.setWalletProvider(wallet);
    //console.log(tezos.wallet)
    const marketContract = await tezos.wallet.at(config.objktMarketAddress);
    const listingContract = await tezos.wallet.at(fa2Contract);
    const userAccount = await wallet.client.getActiveAccount();
    const batchOp = await tezos.wallet.batch()
        .withContractCall(marketContract.methods.retract_ask(askID))

       
        .withContractCall(listingContract.methods.update_operators([
            {
                remove_operator: {
                    owner: userAccount.address,
                    operator: marketContract.address,
                    token_id: tokenID
                }
            }
        ]))
      
        .send();
        await batchOp.confirmation();
      }
    };

export {
  connectWallet,
  disconnectWallet,
  getActiveAccount,
  checkIfWalletConnected,
};
