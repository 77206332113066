import {gql} from '@apollo/client'
import config from '../config'

export const ALL_LISTINGS = gql `
query MyQuery {
  listing(
    where: {currency: {fa_contract: {_in: [${config.supportedTokens}]}}, status: {_eq: "active"}, shares: {_is_null: false}}
    order_by: {timestamp: desc}
  ) {
    id
    bigmap_key
    status
    amount_left
    price
    shares
    level
    currency {
      fa_contract
      decimals
    }
    token {
      fa {
        name
        creator {
          address
          alias
          tzdomain
        }
      }
      supply
      display_uri
      thumbnail_uri
      name
      description
      fa_contract
      token_id
      royalties {
        receiver_address
        amount
        decimals
      }
    }
  }
}
`
