import React from "react";
import classnames from "classnames";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  TabContent,
  TabPane,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import AllListings from "./AllListings";
import PepeListings from "./PepeListings";
import TrashListings from "./TrashListings";
import SkullListings from "./SkullListings";
import PoleListings from "./PoleListings";
import MekListings from "./MekListings";
import UczListings from "./UczListings";
import OttezListings from "./OttezListings";
import DkrbtListings from "./DkrbtListings";

export default function Listings() {
  const [iconTabs, setIconsTabs] = React.useState(1);

  return(
    <>
    
      <div id="alllistings"></div>
        <section >
          <Container className="mt-5">
            <Row>
              <Col md="6">
                <hr className="line-info" />
                <h1>
                  All{" "}
                  <span className="text-info">Listings</span>
                </h1>
                </Col>
                
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12" xl="12">
            <Card>
              <CardHeader>
              <center>
                <Nav className="nav-tabs-info" role="tablist" tabs>
                  
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 1,
                      })}
                      onClick={(e) => setIconsTabs(1)}
                      href="#"
                    >
                      
                      All
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 2,
                      })}
                      onClick={(e) => {
                        
                        setIconsTabs(2)
                        
                      }}
                  
                      href="#"
                    >
                      
                      🐸 <span className="text-success">$PEPE</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 3,
                      })}
                      onClick={(e) => setIconsTabs(3)}
                      href="#"
                    >
                      💈 <span className="text-white">$POLE</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 4,
                      })}
                      onClick={(e) => setIconsTabs(4)}
                      href="#"
                    >
                      👾 <span className="text-danger">$MEK</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 5,
                      })}
                      onClick={(e) => setIconsTabs(5)}
                      href="#"
                    >
                    <span className="text-primary">🖳 $UCZ</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 6,
                      })}
                      onClick={(e) => setIconsTabs(6)}
                      href="#"
                    >
                      🦦 <span className="text-secondary">$OTTEZ</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 7,
                      })}
                      onClick={(e) => setIconsTabs(7)}
                      href="#"
                    >
                      ☠️ <span className="text-default">$SKULL</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 8,
                      })}
                      onClick={(e) => setIconsTabs(8)}
                      href="#"
                    >
                      🗑️ <span className="text-info">$TRASH</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: iconTabs === 9,
                      })}
                      onClick={(e) => setIconsTabs(9)}
                      href="#"
                    >
                      🍑 <span className="text-info">$DKRBT</span>
                    </NavLink>
                  </NavItem>
                  
                </Nav>
                </center>
            
                
                
              </CardHeader>
              <CardBody>
                <TabContent className="tab-space" activeTab={"link" + iconTabs}>
                  <TabPane tabId="link1">
                    <AllListings />
                  </TabPane>
                  <TabPane tabId="link2">
                    <PepeListings />
                  </TabPane>
                  <TabPane tabId="link3">
                    <PoleListings />
                  </TabPane>
                  <TabPane tabId="link4">
                    <MekListings />
                  </TabPane>
                  <TabPane tabId="link5">
                    <UczListings />
                  </TabPane>
                  <TabPane tabId="link6">
                    <OttezListings />
                  </TabPane>
                  <TabPane tabId="link7">
                    <SkullListings />
                  </TabPane>
                  <TabPane tabId="link8">
                    <TrashListings />
                  </TabPane>
                  <TabPane tabId="link9">
                    <DkrbtListings />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Row>
  </Container>
          
</section>

</>
        )

}
