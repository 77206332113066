import {gql , InMemoryCache } from '@apollo/client';
import { useQuery } from "@apollo/client";
import config from "../config";
import React, {useEffect, useState} from "react";
import { useLazyQuery } from '@apollo/client';
import { offsetLimitPagination } from "@apollo/client/utilities";
import { getActiveAccount } from "../utils/wallet";
import { UncontrolledCarousel } from "reactstrap";
import {
  Button,
  Card,
  CardHeader,
  CardBody, 
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal
} from "reactstrap";


export default function Slideshow() {
const [wallet, setWallet] = useState(null);

useEffect(() => {
    const func = async () => {
      const account = await getActiveAccount();
      if (account) {
        setWallet(account.address);
      }
    };
    func();
      }, []);

const ALL_TOKENS = gql `
query MyQuery($offset: Int) {
  token(
    where: {holders: {holder_address: {_eq: "${wallet}"}, quantity: {_gt: "0"}}}
    order_by: {timestamp: desc}
    offset: $offset
  ) {
    token_id
    fa_contract
    display_uri
    name
    supply
    thumbnail_uri
    fa {
      name
      creator {
        address
        alias
        tzdomain
      }
    }
    creators {
      creator_address
    }
    holders(where: {holder_address: {_eq: "${wallet}"}}) {
      quantity
    }
    royalties {
      receiver_address
      amount
      decimals
    }
  }
}
`

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        token: offsetLimitPagination()
      },
    },
  },
});


const {data, fetchMore } = useQuery(ALL_TOKENS, {
  variables: {
    offset: 0,
  },
});


      if (data) {
         
         if (data.token.length === 500) {
          fetchMore({
            variables: {
              offset: 500,
            },
          updateQuery: (previousResult, { fetchMoreResult, variables }) => {
    return {
      ...previousResult,
      // Add the new feed data to the end of the old feed data.
      token: [...previousResult.token, ...fetchMoreResult.token],
    };
  },
          })
          
         }

      }
 let carouselItems = [];
if (data) {
 

  data.token.map((val) => {
   
   let slide = {
    src: val.fa_contract === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw" ? require("assets/img/plenty.jpg")?.default : val.thumbnail_uri ? val.thumbnail_uri.slice(0,4) === "http" ? val.thumbnail_uri : val.thumbnail_uri != "ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc" ? (`https://cf-ipfs.com/ipfs/${val.thumbnail_uri.substring(7)}`) : val.display_uri ? val.display_uri.slice(0,4) === "http" ? val.display_uri : (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg") : val.display_uri ? val.display_uri.slice(0,4) === "http" ? val.display_uri : (`https://cf-ipfs.com/ipfs/${val.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg"),
     
    altText: val.name,
    caption: val.name
  }

  carouselItems.push(slide);
})
}


return (
  <section >
     <div >
        <Container className="pt-5 mt-5" >          

            <Row >
             <Col ></Col>
             <Col md="6" > 
            <UncontrolledCarousel items={carouselItems}   />
            </Col>
            <Col></Col>
            </Row>
          
        </Container>
        </div>
  </section>
)}


