import React, {useEffect, useState} from "react";
import {gql, useQuery, InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from "@apollo/client/utilities";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from "reactstrap";
import { Purchase, CancelListing, getActiveAccount } from "../utils/wallet";
import config from "config";

export default function Profile() {
const [search, setSearch] = useState('');
const [focus, setFocus] = useState( {
      focused: ""
    });

const [wallet, setWallet] = useState(null);

useEffect(() => {
    const func = async () => {
      const account = await getActiveAccount();
      if (account) {
        setWallet(account.address);
      }
    };
    func();
      }, []);
      
const LOAD_LISTINGS = gql `
query MyQuery($offset: Int) {
  listing(
    where: {currency: {fa_contract: {_in: [${config.supportedTokens}]}}, status: {_eq: "active"}, seller_address: {_eq: "${wallet}"}}
    order_by: {timestamp: desc}
    offset: $offset
  ) {
    id
    bigmap_key
    status
    amount_left
    price
    currency {
      fa_contract
      decimals
    }
    token {
      fa {
        name
        creator {
          address
          alias
          tzdomain
        }
      }
      supply
      display_uri
      thumbnail_uri
      name
      description
      fa_contract
      token_id
      operators(
        where: {owner_address: {_eq: "${wallet}"}, operator_address: {_eq: ${config.objktMarketAddress}}}
      ) {
        allowed
      }
    }
  }
}
`

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        listing: offsetLimitPagination()
      },
    },
  },
});

let searchRecords = [];

const {data, fetchMore } = useQuery(LOAD_LISTINGS, {
  variables: {
    offset: 0,
  },
});

const [tokenName, settokenName] = useState([]);
const [loading, setLoading] = useState(true);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(24);
const indexOfLastRecord = currentPage * recordsPerPage;
const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  useEffect(() => {     
      if (data) {
          if (data.listing.length % 500 === 0){
            
            fetchMore({
              variables: {
              offset: data.listing.length,
              },
              updateQuery: (previousResult, { fetchMoreResult, variables }) => {
                return {
                  ...previousResult,
                  listing: [...previousResult.listing, ...fetchMoreResult.listing],
                };
              },
            })
            
          }
          settokenName(data.listing);
          setLoading(false);
      }
  }, [data]);

tokenName.filter((item) => {
                return item
                        ? search.toLowerCase() === '' 
                          ? item
                          : JSON.stringify(item).toLowerCase().includes(search) 
                        : ""}
                )
                .map((item) => (
                 searchRecords.push(item)
                ))


const currentRecords = searchRecords.slice(indexOfFirstRecord, 
                                    indexOfLastRecord);
const nPages = Math.ceil(searchRecords.length / recordsPerPage)
const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

  return(
    <>

        <div id="alllistings"></div>
        <section >
          <Container className="mt-5">
            <Row>
              <Col md="6">
                <hr className="line-info" />
                <h1>
                  Your{" "}
                  <span className="text-info">Listings</span>
                </h1>
                <sup>
                  You can share your listings using this public link - <a href={`/u/${wallet}`} >User Page</a>
                  <br /> If any listing card backgrounds are <span className="text-danger">RED</span> they are invalid need to be re-listed.
                  
                    <InputGroup className={focus.focused} >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="tim-icons icon-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Search..."
                onFocus = {(e) => {
                  setFocus({
                    focused: "input-group-focus"
                  });
                }}
                onBlur = {(e) => {
                  setFocus({
                    focused: ""
                  });
                }}
                onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                
              />
              
            </InputGroup>
                
                </sup>
              </Col>
            </Row>

            <center>
            
            
            
            {wallet
                  ? searchRecords.length 
                    ? ""
                    : loading
                      ? "Loading..."
                      : "🐸 No Listings Found! 🐸"
                  : "🐸 Please Connect a ꜩezos Wallet and Refresh This Page! 🐸"}

            </center>


            <Row>
              {currentRecords.map((val) => {
                
                 return (
                            <Col sm="6" md="3" className="pt-5 mt-5">
                <Card className={`card-coin ${val.token.operators[0].allowed === true ? "bg-plain" : "bg-danger"}`}>
                  <CardHeader className="text-uppercase">
                    <Card className={`card-coin ${val.token.operators[0].allowed === true ? "bg-plain" : "bg-danger"} mt-5 mb-0 p-1`}>
                    <a href={`/token/${val.token.fa_contract}/${val.token.token_id}`} >
                    <img
                      alt="..."
                      style={{ width: "300px" }}
                      className="img-center img-fluid"
                      src={ val.token.fa_contract === "KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw" ? require("assets/img/plenty.jpg")?.default : val.token.thumbnail_uri ? val.token.thumbnail_uri.slice(0,4) === "http" ? val.token.thumbnail_uri : val.token.thumbnail_uri != "ipfs://QmNrhZHUaEqxhyLfqoq1mtHSipkWHeT31LNHb1QEbDHgnc" ? (`https://cf-ipfs.com/ipfs/${val.token.thumbnail_uri.substring(7)}`) : val.token.display_uri ? val.token.display_uri.slice(0,4) === "http" ? val.token.display_uri : (`https://cf-ipfs.com/ipfs/${val.token.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg") : val.token.display_uri ? val.token.display_uri.slice(0,4) === "http" ? val.token.display_uri : (`https://cf-ipfs.com/ipfs/${val.token.display_uri.substring(7)}`) : ("https://pbs.twimg.com/profile_images/1649045912050896902/hUpk8bCP_400x400.jpg")}
                    /></a>
                    </Card>
                 
                    
                    <p align="right"><font color="white"><sub>{val.amount_left} / {val.token.supply}</sub></font></p>
                 
                  </CardHeader>
                  <center><a href={`/token/${val.token.fa_contract}/${val.token.token_id}`} ><big><font color="white" className="font-uppercase">{val.token.name ? val.token.name : "???"}</font></big></a>
                  <hr className="line-default" />
                   <font color="white" className="font-uppercase"><sup>{val.token.fa.name ? val.token.fa.name : ""}</sup>
                  <br /> <sup>{val.token.fa.creator ?  "🎨" : ""}{val.token.fa.creator ? val.token.fa.creator.alias ? val.token.fa.creator.alias : val.token.fa.creator.tzdomain ? val.token.fa.creator.tzdomain : val.token.fa.creator.address ? val.token.fa.creator.address.slice(0, 4) +
                    "..." +
                    val.token.fa.creator.address.slice(val.token.fa.creator.address.length - 4, val.token.fa.creator.address.length) : "" : ""}</sup></font>
                  </center>
                  
                  <CardBody className="text-center">
                    
                    
                  <Button className="btn-simple" color={
                        val.currency.fa_contract === config.poleContract ? "neutral" :
                        val.currency.fa_contract === config.mekContract ? "danger" : 
                        val.currency.fa_contract === config.uczContract ? "primary" : 
                        val.currency.fa_contract === config.ottezContract ? "secondary" :
                        val.currency.fa_contract === config.skullContract ? "default" : 
                        val.currency.fa_contract === config.trashContract ? "info" : 
                        val.currency.fa_contract === config.dkrbtContract ? "secondary" :
                        "success" } 
                        onClick={() => Purchase(1, val.bigmap_key, val.currency.fa_contract, val.price)}
                        >
                        {
                        val.currency.fa_contract === config.poleContract ? (val.price / 100000000) + " 💈 $POLE" :
                        val.currency.fa_contract === config.mekContract ? (val.price / 100000) + " 👾 $MEK" :
                        val.currency.fa_contract === config.uczContract ? (val.price / 100000000) + " 🖳 $UCZ" :
                        val.currency.fa_contract === config.ottezContract ? (val.price / 100000000) + " 🦦 $OTTEZ" :
                        val.currency.fa_contract === config.skullContract ? (val.price / 1000) + " ☠️ $SKULL" :
                        val.currency.fa_contract === config.trashContract ? (val.price / 100000000) + " 🗑️ $TRASH" :
                        val.currency.fa_contract === config.dkrbtContract ? (val.price / 100) + " 🍑 $DKRBT" : 
                        (val.price / 100) + " 🐸 $PEPE"}
                      </Button>

                    <Button color="danger" onClick={() =>  CancelListing(val.token.fa_contract, val.token.token_id, val.bigmap_key)} 
                    >
                      Cancel Listing
                    </Button>
                    
                  </CardBody>
                </Card>
              </Col>
                        );})}
              

                   
            
            


              
              
            </Row>
            <nav>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={prevPage} 
                        href='#'>
                        
                        {nPages > 1 ? "<<" : "" }
                    </a>
                </li>
                {pageNumbers.map(pgNumber => {
                  if ((pgNumber < currentPage - 2 && currentPage > 2 && pgNumber < nPages && pgNumber > 1) || (pgNumber > currentPage + 2 && currentPage < nPages - 2 && pgNumber < nPages && pgNumber > 1) ){
                    return ("")
                  } else {

                      
                  return (
                    
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#'>
                            
                            {pgNumber < currentPage - 2 ? "" : pgNumber}
                        </a>
                    </li>
                )
                  }
                }
                
                )}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={nextPage}
                        href='#'>
                        
                        {nPages > 1 ?  ">>" : "" }
                    </a>
                </li>
            </ul>
        </nav>
          </Container>
          
        </section>

        </>
        )

}
