import {gql} from '@apollo/client'

export const ALL_AUCTIONS = gql `
query MyQuery {
  english_auction_active(order_by: {end_time: asc}) {
    id
    end_time
    highest_bid
    highest_bidder {
      alias
      address
      tzdomain
    }
    bids(order_by: {amount: asc}) {
      amount
      bidder {
        address
        alias
        tzdomain
      }
    }
    price_increment
    reserve
    seller {
      address
      alias
      tzdomain
    }
    shares
    start_time
    token {
      artifact_uri
      creators {
        creator_address
      }
      description
      display_uri
      fa_contract
      name
      thumbnail_uri
      token_id
      fa {
        creator {
          address
          alias
          tzdomain
        }
        name
      }
    }
    currency {
      fa_contract
    }
    price_increment_xtz
  }
}
`

