import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from} from '@apollo/client';
import Index from './views/Index';
import Slideshow from './views/Slideshow';
import Listings from "./views/Listings";
import Auctions from "./views/Auctions";
import Create from "./views/Create";
import Profile from "./views/Profile";
import TokenPage from "./views/TokenPage";
import User from "./views/User";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss";
import "assets/demo/demo.css";
import TrashEvent from "views/TrashEvent";
import TezpoleEvent from "views/TezpoleEvent";
import Dkrbt from "views/DkrbtEvent";

const link = from([
  new HttpLink({ uri: "https://data.objkt.com/v3/graphql" }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <ApolloProvider client={client}> 
              <IndexNavbar />
    </ApolloProvider>
      <BrowserRouter>
        <Routes>
          
          <Route path="slideshow" element={
            <ApolloProvider client={client}> 
              <Slideshow /> 
            </ApolloProvider>
            }
          />

          <Route path="/" element={
            <ApolloProvider client={client}> 
              <Index /> 
            </ApolloProvider>
            }
          />

          <Route path="token/:contract/:tokenID" element={
              <ApolloProvider client={client}>
                <TokenPage />
              </ApolloProvider>
            }
          />

          <Route path="u/:user" element={
              <ApolloProvider client={client}>
                <User />
              </ApolloProvider>
            }
          />
          
          <Route path="/listings" element={
            <ApolloProvider client={client}> 
              <Listings />
            </ApolloProvider>
            }
          />

          <Route path="/auctions" element={
            <ApolloProvider client={client}> 
              <Auctions />
            </ApolloProvider>
            }
          />
          
          <Route path="/create" element={
            <ApolloProvider client={client}>
              <Create />
            </ApolloProvider>
            }
          />
          
          <Route path="/profile" element={
            <ApolloProvider client={client}>
              <Profile />
            </ApolloProvider>
            }
          />

          <Route path="/trash" element={
            <ApolloProvider client={client}>
              <TrashEvent />
            </ApolloProvider>
            }
          />

          <Route path="/tezpole" element={
            <ApolloProvider client={client}>
              <TezpoleEvent />
            </ApolloProvider>
            }
          />

<Route path="/dkrbt" element={
            <ApolloProvider client={client}>
              <Dkrbt />
            </ApolloProvider>
            }
          />
        
        </Routes>
      </BrowserRouter> 
    <Footer />
  </>  
);
