const pepeContract = "KT1MZg99PxMDEENwB4Fi64xkqAVh5d1rv8Z9"
const dogaContract = "KT1Ha4yFVeyzw6KRAdkzq6TxDHB97KG4pZe8"
const trashContract = "KT1ShjB24tTXX8m5oDCrBHXqynCpmNJDxpD5"
const geckoContract = "KT1JBERhXmBgzBAfN27LSBbuJdXfn12vYrrF"
const skullContract = "KT1BEHqhkuqpBoMKuMZSQNx1fEwWp4dranEH"
const objktMarketAddress = "KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC"
const poleContract = "KT1UQVEDf4twF2eMbrCKQAxN7YYunTAiCTTm"
const mekContract = "KT1HMpujRLwyiMKfEaB3QeTKHrFGPBofznUT"
const uczContract = "KT1MczLJtJFaDR22eahgey72v5GYGm7uaBhG"
const ottezContract = "KT18opRzxCFTyhU6iw2g3DUfBy82GRYXqHjH"
const dkrbtContract = "KT18quSVkqhbJS38d5sbRAEkXd5GoNqmAoro"

const config = {
  pepeContract: pepeContract,
  dogaContract: dogaContract,
  trashContract: trashContract,
  geckoContract: geckoContract,
  skullContract: skullContract,
  poleContract: poleContract,
  mekContract: mekContract,
  uczContract: uczContract,
  ottezContract: ottezContract,
  dkrbtContract: dkrbtContract,

  objktMarketAddress: objktMarketAddress,
  
  supportedTokens: [pepeContract, trashContract, skullContract, poleContract, ottezContract, mekContract, uczContract, dkrbtContract],

  featuredCurrency: ["KT18quSVkqhbJS38d5sbRAEkXd5GoNqmAoro"],
  featuredListings: ["KT1Ragmx3T8MyBtrHo59wby9YGN14UQw18Yg", "KT1SEKZw91KhHqHWz1bqqdTK4GS5TyMmVysA", "KT1DiQYsWWFbrSJDNRqjyEEbgfP4CYaZRMq3"],
};

export default config;
